@import '../../App.scss';

.titles {
    margin-left: 20px;

    @media screen and (max-width: 320px) {
        margin-left: 0;
    }
    
    h1 {
        font-size: 96px;
        font-style: italic;
        text-shadow: 1px 1px 2px $dark-color;

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            text-align: center;
            font-size: 48px;
            text-shadow: none;
        }
        @media screen and (max-width: 768px) {
            font-size: 36px;
            span {
                font-size: 96px;
            }
        }
    }
    
    h2 {
        font-size: 40px;

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            text-align: center;
            font-size: 36px;
            margin-bottom: 30px;
        }
    }
}

.text-presentation {
    margin-left: 20px;
    font-style: italic;
    font-weight: bold;
    font-size: 20px;

    @media screen and (max-width: 320px) {
        margin-left: 0;
        padding: 0 30px;
        text-align: justify;
    }
}