@import '../../App.scss';

.project-item {
    box-sizing: border-box;
    width: 100%;
    max-width: 1000px;
    padding: 30px;
    margin-bottom: 40px;
    border-radius: 40px;
    color: $primary-color;
    background-color: $dark-color;

    @media screen and (max-width: 320px) {
        padding: 20px;
        margin-bottom: 20px;
    }
    
    .project-infos {
        @include flex-dir(row);
        @include position-el(space-between, flex-start);

        .infos-1 {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media screen and (max-width: 320px) {
                gap: 10px;
            }
            
            .project-name {
                text-transform: uppercase;
                font-size: 24px;
                font-weight: 200;
                color: $light-color;
            }

            .work-type {
                .work-type-item {
                    display: inline;
                    padding: 5px 10px;
                    margin-right: 10px;
                    border-radius: 10px;
                    color: $dark-color;
                    background-color: $primary-color;

                    @media screen and (max-width: 320px) {
                        font-size: 12px;
                    }
                }
            }

            .project-links {
                .rounded-link {
                    height: 50px;
                    width: 50px;
                    margin-right: 5px;
                    border-radius: 50%;

                    @media screen and (max-width: 320px) {
                        height: 30px;
                        width: 30px;
                    }
                }
            }
        }

        .infos-2 {
            text-align: right;
            
            .project-type {
                text-transform: uppercase;
                display: inline;
                padding: 5px 10px;
                border-radius: 20px;
                color: $dark-color;
                background-color: $light-color;

                @media screen and (max-width: 320px) {
                    padding: 5px 10px;
                    border-radius: 20px;
                    font-size: 10px;
                }
            }

            .project-date {
                margin-top: 15px;
                text-transform: lowercase;

                @media screen and (max-width: 320px) {
                    font-size: 12px;
                }
            }
        }
    }

    .description {
        .intro-description {
            color: $light-color;
            font-weight: 600;
        }

        .project-description, .work-description {
            margin-bottom: 8px;
        }
    }

    .project-image {
        margin-top: 20px;
        width: 100%;
        height: 200px;
        border-radius: 20px;
        object-fit: cover;
    }
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-image {
    max-width: 100%;
    max-height: 60%;
}

.close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: $dark-color;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: $primary-color;
    font-size: 16px;
    padding: 5px 10px;
}
