@import '../../App.scss';

.text-contact {
    font-size: 30px;
    text-align: center;

    @media screen and (max-width: 320px) {
        font-size: 18px;
        padding: 6px;
    }
}

.contact-form {
    box-sizing: border-box;
    width: 100%;
    max-width: 1000px;
    padding: 30px;
    margin-bottom: 40px;
    border-radius: 40px;
    text-transform: lowercase;
    font-size: 20px;
    color: $primary-color;
    background-color: $dark-color;

    @media screen and (max-width: 320px) {
        font-size: 16px;
    }

    input, textarea {
        box-sizing: border-box;
        padding: 25px;
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 20px;
        width: 100%;

    }

    input {
        height: 30px;
        border: none;
    }

    textarea {
        height: 200px;
    }

    .btn {
        padding: 20px 40px;
        border-radius: 40px;
        border: none;
        font-size: 20px;
        color: $dark-color;
        background-color: $primary-color;

        @media screen and (max-width: 320px) {
            font-size: 16px;
            border-radius: 20px;
        }
    
        &:hover {
            box-shadow: 0px 0px 5px $dark-color;
        }
    }
}