$primary-color: #FFE600;
$light-color: white;
$dark-color: black;

$main-font: 'Inter', sans-serif;

// Layouts elements
@mixin flex-dir($direction) {
    display: flex;
    flex-direction: $direction;
}

@mixin position-el($axe1, $axe2) {
    justify-content: $axe1;
    align-items: $axe2;
}

* {
    margin: 0;
    padding: 0;
    font-family: $main-font;
}

body {
    background-color: $primary-color;
    background-image: linear-gradient(rgba(255, 166, 0, 0.3) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 166, 0, 0.3) 1px, transparent 1px);
    background-size: 48px 48px;
}

main {
    @include flex-dir(column);
    @include position-el(center, center);
    gap: 70px;
    margin: 100px auto;
    padding: 0 250px;
    max-width: 1440px;

    @media screen and (max-width:1150px) {
        gap: 40px;
        margin: 40px;
        padding: 70px;
    }
    @media screen and (max-width:768px) {
        margin: 20px;
        padding: 5px;
    }
    @media screen and (max-width:320px) {
        margin: 20px 0px;
        padding: 0px;
    }
}

a {
    color: $dark-color;
}

.page-title {
    font-weight: 200;
    font-size: 48px;

    @media screen and (max-width:320px) {
        font-weight: 400;
        font-size: 32px;
    }
}