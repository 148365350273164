@import '../../App.scss';

.skill-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 10px;

    @media screen and (max-width: 570px) {
        flex-direction: column;
    }

    .skill-name {
        font-weight: bold;
        text-transform: lowercase;

        @media screen and (max-width: 720px) {
            margin-bottom: 10px;
        }   
        @media screen and (max-width: 320px) {
            margin-bottom: 5px;
            font-size: 16px;
        }   
    }

    .skill-level {
        display: flex;

        .rectangle {
            width: 35px;
            height: 15px;
            border: 2px solid $primary-color;
            // background-color: $light-color;
            margin: 0 6px;
            border-radius: 5px;

            &.filled {
                background-color: $primary-color;
            }

            @media screen and (max-width: 320px) {
                height: 10px;
            }   
        }
    }

    .check-icon {
        height: 35px;

        @media screen and (max-width: 320px) {
            height: 25px;
        }   
    }
}
