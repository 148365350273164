@import '../../App.scss';

.btn {
    padding: 20px 40px;
    border-radius: 40px;
    border: none;
    font-size: 20px;
    color: $primary-color;
    background-color: $dark-color;

    @media screen and (max-width: 320px) {
        padding: 10px 20px;
        font-size: 14px;
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 5px $dark-color;
    }
}