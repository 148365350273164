@import '../../App.scss';

footer {
    @include flex-dir(column);
    @include position-el(center, center);
    height: 100px;

    color: $primary-color;
    background-color: $dark-color;

    .text-footer {
        text-align: center;

        @media screen and (max-width: 320px) {
            font-size: 12px;
        }
    }
}