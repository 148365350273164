@import '../../App.scss';

.box-skills {
    box-sizing: border-box;
    width: 100%;
    max-width: 1000px;
    padding: 30px;
    border-radius: 40px;
    background-color: $dark-color;

    .title-box {
        font-size: 24px;
        font-weight: 200;
        color: $light-color;
    }

    .skills {
        @include flex-dir(row);
        @include position-el(center, flex-start);

        margin-top: 30px;

        .block-skills {
            width: 100%;
        }

        .frontend {
            margin-right: 25px;
        }

        .skill-list {
            list-style-type: none;
            color: $primary-color;
            font-size: 20px;
            font-weight: 600;

            li {
                padding: 8px;
            }
        }
    }

}